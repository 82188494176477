import React, { useEffect, useContext } from 'react';
import { string, bool } from 'prop-types';
import {
  extend,
  params,
  shape as shapeType,
  bool as boolType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { useDataModel } from '@thd-nucleus/data-sources/react/dataModel/useDataModel';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  CardContent, CardTitle, Typography, Link, SkeletonLine
} from '@one-thd/sui-atomic-components';
import { Returns } from '@one-thd/sui-icons';

const ReturnsMessaging = ({ itemId, displayTimeframe }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('returns-messaging.ready'); }, []);

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading && !data) {
    return (
      <CardContent
        className="sui-bg-primary sui-px-3 sui-py-1 sui-mb-3 sui-overflow-hidden"
        data-component="ReturnsMessagingPlaceholder"
      >
        <SkeletonLine variant="multi" numberOfLines={2} />
      </CardContent>
    );
  }

  if (
    (error && !data)
    || data?.product?.info?.returnable === 'Non-Returnable'
    || typeof data?.product?.info?.returnable !== 'string'
  ) {
    return null;
  }

  const { returnable, isInStoreReturnMessageEligible } = data.product.info;
  const hasTimeframe = /[0-9]+-\w+/.test(returnable);
  const returnMessage = returnable.replace('-', ' ').toLowerCase() + 's';

  return (
    <>
      {displayTimeframe && hasTimeframe && (
        <CardContent
          className={`sui-flex sui-bg-primary sui-gap-3 sui-p-3 sui-w-full sui-rounded-base sui-overflow-hidden 
          ${isMobile ? 'sui-my-3' : 'sui-mb-3'}`}
          data-component="ReturnsMessaging"
          data-testid="returns-messaging"
        >
          <div className="sui-mt-1">
            <Returns color="primary" size="large" />
          </div>
          <div className="sui-flex sui-flex-col sui-gap-1 sui-w-full">
            <div>
              <CardTitle
                header={(
                  <Typography variant="h4">
                    {`Free & Easy Returns In Store ${isInStoreReturnMessageEligible ? '' : 'or Online'}`}
                  </Typography>
                )}
              />
              <Typography variant="body-base" color="primary">
                {'Return this item within '}
                <Typography component="span" variant="body-base" weight="bold">{returnMessage}</Typography>
                {' of purchase. '}
              </Typography>
            </div>
            <Link href="https://www.homedepot.com/c/Return_Policy" target="_blank">
              Read Return Policy
            </Link>
          </div>
        </CardContent>
      )}
    </>
  );
};

ReturnsMessaging.displayName = 'ReturnsMessaging';

ReturnsMessaging.dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    info: shapeType({
      returnable: stringType(),
      isInStoreReturnMessageEligible: boolType()
    })
  }),
});

ReturnsMessaging.propTypes = {
  itemId: string.isRequired,
  displayTimeframe: bool,
};

ReturnsMessaging.defaultProps = {
  displayTimeframe: false,
};

export { ReturnsMessaging };
